<template>
  <div>
    <h4 class="mt-5 mt-lg-3 mb-3">
      Additional Details
    </h4>

    <dl v-for="(detail, index) in details" :key="index" class="row">
      <dt class="col-lg-12 col-6">
        {{ detail.label }}
      </dt>
      <dd class="col-lg-12 col-6">
        {{ detail.value }}
      </dd>
    </dl>
  </div>
</template>

<script>
import http from '@/http'

export default {
  name: 'InvoiceAdditionalDetails',
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      details: {},
    }
  },
  async mounted() {
    await this.getInvoiceAdditionalDetails()
  },
  methods: {
    async getInvoiceAdditionalDetails () {
      const response = await http.get(`client/invoices/additional_details/${this.invoice.id}`)
      this.details = response?.data?.result || []
      this.$emit('hasDetails', this.details.length > 0)
    },
  },
}
</script>

<style lang="scss" scoped>
dl {
  font-size: 0.875em;
}
</style>

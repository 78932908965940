var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", { staticClass: "mt-5 mt-lg-3 mb-3" }, [
        _vm._v("\n    Additional Details\n  "),
      ]),
      _vm._l(_vm.details, function (detail, index) {
        return _c("dl", { key: index, staticClass: "row" }, [
          _c("dt", { staticClass: "col-lg-12 col-6" }, [
            _vm._v("\n      " + _vm._s(detail.label) + "\n    "),
          ]),
          _c("dd", { staticClass: "col-lg-12 col-6" }, [
            _vm._v("\n      " + _vm._s(detail.value) + "\n    "),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }